import { Link, useNavigate } from 'react-router-dom';
import logo from '../../img/MetaNinjaLogo.png';
import React, { useEffect, useState } from "react";
import { IoWallet } from "react-icons/io5";
import profile from '../../img/profile.png';
import tab from "./images/icon.svg"
// import logo from '../../img/MetaNinjaLogo.png';
// import Dropdown from '../../Component/Dropdown';
// import BackgroundImg from './images/headerBackground.png'

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Icons from './images/Group 3.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import bgMedia from '../Media/bgMedia.mp4';
import MyImg from '../../img/sliderimg1.png';
import MyImg2 from '../../img/image (1).png';
import MyImg3 from '../Navabr/images/slider3rd.png';
// import Web3 from "web3";
// import { toast } from "react-toastify";
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import WalletIcon from './images/wallet-icon.png';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = () => {
  const navigate = useNavigate();
  const [isCommunityHovered, setIsCommunityHovered] = useState(false);
  const [account, setAccount] = useState("");
  const [web3, setWeb3] = useState(null);

  const navigation = [
    { name: "STRIKE", href: "/", current: true },
    { name: "GAMES", href: "/games", current: false },
    { name: "MARKETPLACE", href: "/nft", current: false },
    { name: "GALLERY", href: "/gallery", current: false },
    // { name: 'MARKETPLACE', href: '/marketplace', current: false },
    { name: "COMMUNITY", href: "#", current: false },
    { name: "ABOUT US", href: "/aboutUs", current: false },
    {
      name: "WHITEPAPER",
      href: "https://starkmeta-team.gitbook.io/meta-ninja-strike/core-technology",
      current: false,
      target: "_blank",
    },
    // { name: <Dropdown />, href: '#', current: false },
  ];

  const communityLinks = [
    {
      id: 1,
      link: "https://twitter.com/Starkmetagame",
      name: "Twitter",
    }, {
      id: 2,
      link: "https://discord.gg/starkmeta",
      name: "Discord",
    }, {
      id: 3,
      link: "https://github.com/starkmetadev",
      name: "Github",
    }, {
      id: 4,
      link: "https://www.youtube.com/@GameStarkMeta",
      name: "Youtube",
    }, {
      id: 5,
      link: "https://www.instagram.com/starkmetagame",
      name: "Instagram",
    },
  ]
  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector('.main-wrapper');
      if (window.scrollY > 7) {
        nav.classList.add('nav-bg');
      } else {
        nav.classList.remove('nav-bg');
      }
    };

    // Set up the event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   loadWeb3();
  //   loadBlockchainData();
  // }, [web3]);

  // const loadWeb3 = async () => {
  //   try {
  //     if (window.ethereum) {
  //       window.web3 = new Web3(window.ethereum);
  //       await window.ethereum.enable();
  //       setWeb3(window.web3);
  //     } else if (window.web3) {
  //       window.web3 = new Web3(window.web3.currentProvider);
  //       setWeb3(window.web3);
  //     } else {
  //       console.log("MetaMask not detected. Please install MetaMask.");
  //       toast.warn("MetaMask not detected. Please install MetaMask.");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(error?.message);
  //   }
  // };

  // const loadBlockchainData = async () => {
  //   if (web3) {
  //     const accounts = await web3.eth.getAccounts();
  //     if (accounts?.length) {
  //       setAccount(accounts[0]);
  //       // toast.success("MetaMask wallet connected successfully!");
  //     }
  //   }
  // };


  // const handleWallet = () => {
  //   // loadWeb3();
  //   // loadBlockchainData();
  // }

  return (
    <div className="w-full m-auto ">
      {/* <div>
            <img src={BackgroundImg} alt=''/>
        </div> */}
      <Disclosure as="nav" className="main-wrapper w-full">
        {({ open }) => (
          <>
            {/* <div className='w-full'>
            <img src={Icons} alt='' className='absolute mt-[300px] z-20 w-full' />
          </div> */}

            <div className="px-2  w-full z-20">
              <div className="relative flex items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 bg-[#FFBF00] hover:text-white ">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center lg:items-center lg:justify-between ml-[20px]">
                  <div className="flex flex-shrink-0 lg:w-[15%] items-center z-10">
                    <img
                      className="h-[90px] w-[150px] cursor-pointer"
                      src={logo}
                      onClick={() => navigate('/')}
                      alt="Meta Ninja Strike - Logo"
                    />
                  </div>
                  {/* <div className="nav-cutting-parent bg-[#FFBF00] h-[44px] flex items-center lg:w-[83%] xl:w-[80%] "> */}
                  <div className="hidden h-[40px] mx-0.5 lg:block bg-black xl:mx-auto   nav-before">
                    <div className="flex justify-center text-center relative">
                      {navigation.map((item) => (
                        <div key={item.name} className="relative group my-2">
                          {item.name === 'COMMUNITY' ? (
                            <>
                              <Menu
                                as="div"
                                className="relative  text-left z-20 "
                              >
                                <Menu.Button
                                  href={item.href}
                                  className="text-[13px] font-medium text-white hover:bg-[#FFBF00] hover:text-black px-3 py-1 pt-[11px] pb-[6px] mt-[-9px] flex justify-center col h-full custom-media-text"
                                >
                                  {item.name}
                                </Menu.Button>
                                <Menu.Items className=" bg-black shadow-lg ring-1 ring-black/5 focus:outline-none z-30">
                                  {communityLinks?.map((item) => {
                                    return (
                                      <Menu.Item>
                                        <Link
                                          to={item?.link}
                                          target="_blank"
                                          className="flex justify-center items-center px-4 py-2 text-[12px] hover:text-black text-white hover:bg-[#FFBF00]"
                                        >
                                          <span>{item?.name}</span>
                                          <img src={tab} alt="" className="hover:text-white h-[15px] ml-2" />
                                        </Link>
                                      </Menu.Item>
                                    )
                                  })}

                                  {/* <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={`text-[13px] font-medium text-white hover:bg-[#FFBF00] hover:text-black px-3 py-3.5 flex justify-center col h-full custom-media-text`}
                                      >
                                        COMMUNITY
                                      </a>
                                    )}
                                  </Menu.Item> */}
                                </Menu.Items>
                              </Menu>
                            </>
                          ) : (
                            <>
                              <a
                                href={item.href}
                                className={`text-[13px] font-medium text-white hover:bg-[#FFBF00] hover:text-black py-3 pt-[16px] px-3 w-full h-full custom-media-text ${item.name === 'COMMUNITY' && 'relative'
                                  }`}
                                aria-current={item.current ? 'page' : undefined}
                              >
                                {item.name}
                              </a>
                            </>
                          )}


                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0 lg:w-[17%]">

                  <Menu as="div" className="relative">
                    <div className='mr-3'>
                      <Menu.Button className="relative flex rounded-full text-sm">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <div className="cont_wallet-btn-parent w-full  h-[70px] z-10 xl:ml-6">
                          <button className="cont_wallet-btn  sm:block px-8 lg:px-3 xl:px-5 md:text-[15px] h-[70px] text-[10px] font-medium text-black  w-full">
                          <IoWallet className='text-[#FFBF00] text-5xl' />
                          </button>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      // as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="hidden absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <>
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'text-white hover:bg-[#FFBF00] hover:text-black'
                          : 'text-gray-300 hover:bg-[#FFBF00] hover:text-black',
                        'block rounded-md px-3 py-2 text-[15px] font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  </>
                ))}
                <div className="hidden cont_wallet-btn-parent sm:hidden w-[80%] bg-[#FFBF00] h-[70px] z-10 xl:ml-6 mx-auto">
                  <button className="cont_wallet-btn  px-3 lg:px-3 xl:px-5 md:text-[15px] h-[70px] text-[10px] font-medium text-black  w-full">
                    Connect Wallet
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Navbar;