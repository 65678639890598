
import { Suspense } from 'react';
import RoutesComponent from 'Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingComponent from 'Components/LoadingComponent';
// import Race from '../src/Components/NFT/Race'
import 'animate.css/animate.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './App.css';


function App() {
  return (
    <>
      <Suspense fallback={<LoadingComponent />}>
        <RoutesComponent />
        <ToastContainer />
      </Suspense>
    </>

  );
}

export default App;
