import React from 'react';
import GameHeaderVideo from '../../img/elements/NFT/nft cards animation - Trim .mp4'

export default function GameHeader() {
    return (
        <>
            <video loop autoPlay muted>
                <source type="video/mp4" src={GameHeaderVideo}></source>
            </video>
        </>
    )
}
