import React from 'react';
import footerLogo from './footerImages/footer-logo.png';
import footerLogo1 from './footerImages/footer-logo1.png';
import footerLogo2 from './footerImages/footer-logo2.png';
import footerLogo3 from './footerImages/footer-logo3.png';


import footershap1 from './footerImages/footer-shap-1-v2.png';
import footershap2 from './footerImages/footer-shap-2-v2.png';

const MetaFooterV2 = () => {

    return (
        <>
            <div className='flex flex-col  bg-dots-img footerBackgroundV2  bg-contain w-full sm:h-[300px] md:h-[500px] lg:h-[500px] h-[300px] '>
                <div>
                    <img src={footershap1} alt="" className="sm:w-auto w-[30%]" />
                </div>

                <div className='flex w-[90%] mx-auto my-auto justify-center flex-wrap'>
                    <img src={footerLogo} alt="" className="md:w-auto w-[20%] m-[2%]" />
                    <img src={footerLogo1} alt="" className="md:w-auto w-[20%] m-[2%]" />
                    <img src={footerLogo2} alt="" className="md:w-auto w-[20%] m-[2%]" />
                    <img src={footerLogo3} alt="" className="md:w-auto w-[20%] m-[2%]" />
                </div>

                <div >
                    <img src={footershap2} alt="" className="sm:w-auto w-[30%] float-right" />
                </div>
            </div>

            <div className='bg-[#ffbf00] h-[100px] flex items-center'>
                <div className='mx-auto'>
                    <h1 className='font-[500] text-center text-md sm:text-2xl'>FIND US ON</h1>
                    <div className='flex justify-center'>
                        <i className="fa-brands text-md sm:text-3xl cursor-pointer fa-tiktok"></i>
                        <i className="fa-brands text-md sm:text-3xl cursor-pointer ml-2  fa-instagram"></i>
                        <i className="fa-brands text-md sm:text-3xl cursor-pointer ml-2  fa-linkedin"></i>
                        <i className="fa-brands text-md sm:text-3xl cursor-pointer ml-2 fa-youtube"></i>
                        <i className="fa-brands text-md sm:text-3xl cursor-pointer ml-2 fa-twitter"></i>
                        <i className="fa-brands text-md sm:text-3xl cursor-pointer ml-2 fa-facebook"></i>
                    </div>


                </div>
            </div>
            <div className='text-white text-center mb-10'> Copyright 2024 META NINJA STRIKE 2080 ALL RIGHTS RESERVED </div>
        </>
    );
};

export default MetaFooterV2;
