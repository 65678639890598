import React from "react";
import Navbar from "Components/Navabr";
import MetaFooter from "Components/MetaFooterV2";
import Header from "Components/Header";
import GameHeader from "Components/GameHeader";


function WebLayout({ children, showHeader = true, showGameHeader= false }) {
    return (
        <>
            <Navbar />
            {showHeader && <Header />}
            {showGameHeader && <GameHeader />}
            {children}

            <MetaFooter />
        </>
    );
}

export default WebLayout;
